export default {
    props: {
        gameLogo: {
            type: Object,
            default: () => ({
                img: '',
                url: ''
            })
        },
        platLogo: {
            type: Object,
            default: () => ({
                img: '',
                url: ''
            })
        },
        links: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        hrefUrl(url) {
            return url || 'javascript:;'
        },
        targetType(url) {
            return url ? '_blank' : '_self'
        }
    }
}