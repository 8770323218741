<!-- 
 * @FileDescription: 
 * @Author: yangjinhong
 * @Date: 2024/10/24 10:57
 * @LastEditors: yangjinhong
 * @LastEditTime: 2024/10/24
-->

<template>
  <PopupM
      v-model="visible"
      @close="SET_IS_POP_SAVE_DESKTOP(false)"
      width="100%"
      position="bottom"
  >
    <div class="save_desktop_m">
      <div class="content" v-if="isIos">
        <div class="step_1">
          <span>{{ $t("sys.pop.stepOne") }}</span>{{ $t("sys.pop.stepOneText") }}
        </div>
        <div class="img">
          <img class="img_style" :src="require('@/assets/img/popbg/safari_add_desktop.png')">
        </div>
        <div class="step_2">
          <span>{{ $t("sys.pop.stepTwo") }}</span>{{ $t("sys.pop.stepTwoText") }}
        </div>
        <div class="close_save_desktop_pop" @click="confirmBtn" >
              <span class="confirm_button">
                {{ $t("sys.btn.gotIt") }}
              </span>
        </div>
      </div>
      <div class="content" v-else>
        <div v-html="$t('sys.pop.saveDesktopAndroid')"></div>
        <div class="close_save_desktop_pop" @click="confirmBtn" >
              <span class="confirm_button">
                {{ $t("sys.btn.gotIt") }}
              </span>
        </div>
      </div>
    </div>
  </PopupM>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {isIos} from '@/utils/constants'
import PopupM from "@/components/Popup/M.vue";
import {logPopupClick} from "@/utils/log";
export default {
  components: {PopupM},
  data() {
    return {
      isIos
    }
  },
  computed: {
    ...mapState('user', ['isPopSaveDesktop']),
    visible: {
      get() {
        return this.isPopSaveDesktop
      },
      set(val) {
        this.SET_IS_POP_SAVE_DESKTOP(val)
      }
    }
  },
  methods: {
    ...mapMutations('user', ['SET_IS_POP_SAVE_DESKTOP']),
    confirmBtn () {
      let popupClick = {
        popup_type: 'popup_mb',
        click_result: 'confirm'
      }
      logPopupClick(popupClick);
      this.SET_IS_POP_SAVE_DESKTOP(false)
    }
  }
}
</script>

<style scoped lang="scss">

</style>