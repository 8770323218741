var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      on: {
        close: function ($event) {
          return _vm.SET_IS_POP_HELP(false)
        },
      },
      model: {
        value: _vm.isPopHelp,
        callback: function ($$v) {
          _vm.isPopHelp = $$v
        },
        expression: "isPopHelp",
      },
    },
    [
      _c("div", {
        staticClass: "help_info",
        domProps: { innerHTML: _vm._s(_vm.$xy("user.helpContent")) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }