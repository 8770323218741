var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      attrs: {
        value: _vm.isPopLogin,
        width: _vm.isMobile ? "95%" : "540px",
        modelClose: false,
      },
      on: { close: () => _vm.SET_IS_POP_LOGIN(false) },
    },
    [
      _c("iframe", {
        attrs: {
          width: "100%",
          height: _vm.isMobile ? _vm.popHeight : 300,
          scrolling: "no",
          frameborder: "0",
          src: _vm.loginUrl,
          id: "loginIframe",
          allowtransparency: "true",
        },
        on: {
          load: function ($event) {
            return _vm.loadEnd()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }