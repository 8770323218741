<template>
    <component :is="isMobile ? 'FooterM' : 'FooterPc'" :gameLogo="gameLogo" :platLogo="platLogo" :links="links">
    </component>
</template>

<script>
import { mapGetters } from 'vuex'
import { getGameLogoByUrl, getGamePlatLogoByUrl, isCyGat } from '@/utils/game'
import { logPolicy, logRule, logFunsClub, logCustomerCenter } from '@/utils/log'
import FooterPc from './Pc'
import FooterM from './M'

export default {
    name: 'LayoutFooter',
    components: {
        FooterPc,
        FooterM
    },
    computed: {
        ...mapGetters(['isMobile', 'lang']),
        gameLogo() {
            return {
                img: getGameLogoByUrl(this.lang, 'white')
            }
        }
    },
    data() {
        return {
            platLogo: {
                img: isCyGat() ? require('images/logo/wjgy-gray.png') : getGamePlatLogoByUrl()
            },
            links: [
                {
                    label: 'footer.termsOfService',
                    url: 'footer.link_protocol',
                    handleClick: logPolicy
                },
                {
                    label: 'footer.privacyPolicy',
                    url: 'footer.link_privacy',
                    handleClick: logRule
                },
                {
                    label: 'footer.officialFanGroup',
                    url: 'footer.link_officialFanGroup',
                    handleClick: logFunsClub
                },
                {
                    label: 'footer.serviceCenter',
                    email: this.$xy('footer.email_service'),
                    handleClick: logCustomerCenter
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped></style>