<!--
 * @FileDescription:
 * @Author: yangjinhong
 * @Date: 2024/10/24 10:57
 * @LastEditors: yangjinhong
 * @LastEditTime: 2024/10/24
-->

<template>
  <Popup
      v-model="isPopSaveDesktop"
      @close="SET_IS_POP_SAVE_DESKTOP(false)"
      :show-close="false"
      :width="isMobile ? '90%' : '496px'"
  >
    <div class="save_desktop_pc">
      <div class="title">{{ $t("sys.pop.bookmarkPage") }}</div>
      <div class="content" v-html="$t('sys.pop.bookmarkPageContent')" />
      <div class="img">
        <img :src="require('@/assets/img/popbg/addfavorite.png')">
      </div>
      <div class="close_save_desktop_pop" @click="confirmBtn" >
            <span class="confirm_button">
              {{ $t("sys.btn.gotIt") }}
            </span>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup/index.vue';
import { isChrome } from '@/utils/constants';
import { logPopupClick } from '@/utils/log';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {Popup},
  data() {
    return {
      isChrome
    }
  },
  computed: {
    ...mapState('user', ['isPopSaveDesktop']),
    ...mapGetters(['isMobile']),
  },
  methods: {
    ...mapMutations('user', ['SET_IS_POP_SAVE_DESKTOP']),
    confirmBtn () {
      let popupClick = {
        popup_type: 'popup_pc2',
        click_result: 'confirm'
      }
      logPopupClick(popupClick);
      this.SET_IS_POP_SAVE_DESKTOP(false)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
