var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PopupM",
    {
      attrs: { width: "100%", position: "bottom" },
      on: {
        close: function ($event) {
          return _vm.SET_IS_POP_SAVE_DESKTOP(false)
        },
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "save_desktop_m" }, [
        _vm.isIos
          ? _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "step_1" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("sys.pop.stepOne")))]),
                _vm._v(_vm._s(_vm.$t("sys.pop.stepOneText")) + " "),
              ]),
              _c("div", { staticClass: "img" }, [
                _c("img", {
                  staticClass: "img_style",
                  attrs: {
                    src: require("@/assets/img/popbg/safari_add_desktop.png"),
                  },
                }),
              ]),
              _c("div", { staticClass: "step_2" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("sys.pop.stepTwo")))]),
                _vm._v(_vm._s(_vm.$t("sys.pop.stepTwoText")) + " "),
              ]),
              _c(
                "div",
                {
                  staticClass: "close_save_desktop_pop",
                  on: { click: _vm.confirmBtn },
                },
                [
                  _c("span", { staticClass: "confirm_button" }, [
                    _vm._v(" " + _vm._s(_vm.$t("sys.btn.gotIt")) + " "),
                  ]),
                ]
              ),
            ])
          : _c("div", { staticClass: "content" }, [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("sys.pop.saveDesktopAndroid")),
                },
              }),
              _c(
                "div",
                {
                  staticClass: "close_save_desktop_pop",
                  on: { click: _vm.confirmBtn },
                },
                [
                  _c("span", { staticClass: "confirm_button" }, [
                    _vm._v(" " + _vm._s(_vm.$t("sys.btn.gotIt")) + " "),
                  ]),
                ]
              ),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }