var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      attrs: { "show-close": false, width: _vm.isMobile ? "90%" : "496px" },
      on: {
        close: function ($event) {
          return _vm.SET_IS_POP_SAVE_DESKTOP(false)
        },
      },
      model: {
        value: _vm.isPopSaveDesktop,
        callback: function ($$v) {
          _vm.isPopSaveDesktop = $$v
        },
        expression: "isPopSaveDesktop",
      },
    },
    [
      _c("div", { staticClass: "save_desktop_pc" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("sys.pop.bookmarkPage"))),
        ]),
        _c("div", {
          staticClass: "content",
          domProps: {
            innerHTML: _vm._s(_vm.$t("sys.pop.bookmarkPageContent")),
          },
        }),
        _c("div", { staticClass: "img" }, [
          _c("img", {
            attrs: { src: require("@/assets/img/popbg/addfavorite.png") },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "close_save_desktop_pop",
            on: { click: _vm.confirmBtn },
          },
          [
            _c("span", { staticClass: "confirm_button" }, [
              _vm._v(" " + _vm._s(_vm.$t("sys.btn.gotIt")) + " "),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }