var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.isMobile ? "layoutM" : "layoutPc",
    { tag: "component" },
    [
      _c("Header", [
        _c("div", { staticClass: "flex-center" }, [
          _c(
            "a",
            {
              attrs: {
                href: _vm.gameLink || "javascript:;",
                target: _vm.gameLink ? "_blank" : "_self",
              },
            },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.gameLogo, alt: "" },
              }),
            ]
          ),
        ]),
      ]),
      _c(
        "Content",
        { staticClass: "page_main" },
        [_vm.isInitFinish ? _c("router-view") : _vm._e()],
        1
      ),
      _c("Footer", [_c("XyFooter")], 1),
      _c("PopHelp"),
      _c("PopLogin"),
      _c("PopSaveDesktop"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }