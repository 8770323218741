<!-- 
 * @FileDescription: 
 * @Author: yangjinhong
 * @Date: 2024/10/22 18:27
 * @LastEditors: yangjinhong
 * @LastEditTime: 2024/10/22
-->

<template>
  <div class="save_desktop_pop">
    <component :is="isMobile ? 'SaveDesktopM' : 'SaveDesktopPc'">
    </component>
  </div>
</template>

<script>
import {getGameNameByUrl} from "@/utils/game";
import Popup from "@/components/Popup/M.vue";
import { mapMutations, mapGetters, mapState } from 'vuex'
import {isChrome, isIos} from '@/utils/constants'
import SaveDesktopPc from './PC.vue'
import SaveDesktopM from './M.vue'

export default {
  name: "PopSaveDesktop",
  components: {
    SaveDesktopPc,
    SaveDesktopM,
    Popup
  },
  data(){
    return{
      isChrome,
      isIos
    }
  },
  computed: {
    ...mapState('user', ['deferredPrompt']),
    ...mapGetters(['isMobile', 'lang']),
  },
  watch: {
    lang(newVal, oldVal){
      if(!this.isMobile && this.isChrome){
        const nowLink = document.querySelector(`link[href="/saveDesktop/${getGameNameByUrl()}/${oldVal}/manifest.json"]`);
        if (nowLink) {
          nowLink.parentNode.removeChild(nowLink);
        }

        // 当前浏览器为Chrome内核, 如果此时当前语言的manifest.json不存在，则添加
        let link = document.createElement('link');
        link.rel = 'manifest';
        link.href = `/saveDesktop/${getGameNameByUrl()}/${newVal}/manifest.json`;
        document.head.appendChild(link);

        window.removeEventListener("beforeinstallprompt", this.addBeforeinstallprompt);
        window.removeEventListener("appinstalled", this.addAppinstalled );

        window.addEventListener("beforeinstallprompt", this.addBeforeinstallprompt);
        window.addEventListener("appinstalled", this.addAppinstalled );
      }
    }
  },
  mounted(){
    if (this.isMobile) {
      // 当前浏览器为移动端
      // 添加保存到桌面的meta和link信息
      document.title = this.$t('sys.header.saveDesktopTitle')
      let meta = document.createElement('link');
      meta.name = 'application-name';
      // `${getGameNameByUrl()}.game.titleGat`
      meta.content = this.$t('sys.header.saveDesktopTitle')
      document.head.appendChild(meta);
      let link = document.createElement('link');
      link.rel = 'apple-touch-icon-precomposed';
      link.sizes = '144x144'
      link.href = `/static/img/${getGameNameByUrl()}/icon-144x144.png`;
      document.head.appendChild(link);
    } else {
      // 当前浏览器为PC端
      if (this.isChrome) {
        // 当前浏览器为Chrome内核
        let link = document.createElement('link');
        link.rel = 'manifest';
        link.href = `/saveDesktop/${getGameNameByUrl()}/${this.$i18n.locale}/manifest.json`;
        document.head.appendChild(link);

        // if ("serviceWorker" in navigator) {
        //   navigator.serviceWorker
        //       .register('/serviceWorker.js')
        //       .then(res => console.log("service worker registered"))
        //       .catch(err => console.log("service worker not registered", err));
        // }
        window.removeEventListener("beforeinstallprompt", this.addBeforeinstallprompt);
        window.removeEventListener("appinstalled", this.addAppinstalled );

        window.addEventListener("beforeinstallprompt", this.addBeforeinstallprompt);
        window.addEventListener("appinstalled", this.addAppinstalled );
      }
    }
  },
  methods: {
    ...mapMutations('user', ['SET_IS_POP_SAVE_DESKTOP', 'SET_DEFERRED_PROMPT']),
    addBeforeinstallprompt(e) {
      e.preventDefault();
      this.SET_DEFERRED_PROMPT(e);
    },
    addAppinstalled(){
      this.SET_DEFERRED_PROMPT(null);
    }
  }
}
</script>

<style scoped lang="scss">

</style>